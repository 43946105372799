import { useCallback, useEffect, useState } from 'react';

import { Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import { values } from 'mobx';
import { observer } from 'mobx-react';

import MaintenanceWindowsList from '../components/MaintenanceWindowsList/MaintenanceWindowsList';
import MonitorList from '../components/MonitorsList/MonitorList';
import NotesSection from '../components/NotesSection/NotesSection';
import PageHeader from '../components/PageHeader/PageHeader';
import SettingsDialog from '../components/SettingsDialog/SettingsDialog';
import notes from '../notes/notes.json';
import { useStore } from '../store/useStore';

const LOCAL_STORAGE_KEY_USER_SETTINGS = 'user_settings';

const HomePage = observer(() => {
  const store = useStore();
  const [monitorList, setMonitorList] = useState([]);
  const [settingsDialogOpened, setSettingsDialogOpen] = useState(false);
  const [updatedAt, setUpdatedAt] = useState(new Date());

  const [settings, setSettings] = useState({
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const fetchData = useCallback(() => {
    store.maintenanceStore.fetchMaintenances();

    store.metricStore.fetchMonitors().then(() => {
      setMonitorList(values(store.metricStore.monitors));

      values(store.metricStore.monitors).forEach((monitor) => {
        monitor.fetchMetrics().then(() => {
          setMonitorList(values(store.metricStore.monitors));
        });
      });
    });

    setUpdatedAt(new Date());
  }, [store.maintenanceStore, store.metricStore]);

  useEffect(() => {
    const storedSettingsString = localStorage.getItem(LOCAL_STORAGE_KEY_USER_SETTINGS);

    if (storedSettingsString) {
      try {
        const storedSettings = JSON.parse(storedSettingsString);
        setSettings({
          ...settings,
          ...storedSettings,
        });
      } catch (e) {
        // JSON deserialization error, just remove the corrupted settings string from localeStorage and use default one
        localStorage.removeItem(LOCAL_STORAGE_KEY_USER_SETTINGS);
      }
    }

    fetchData();
    const interval = setInterval(fetchData, 300000); // fetch updated data every 5 minutes

    return () => clearInterval(interval); // cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSettingsChange = (newSettings) => {
    setSettings(newSettings);
  };

  const onSettingsDialogClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY_USER_SETTINGS, JSON.stringify(settings));
    setSettingsDialogOpen(false);
  };

  return (
    <>
      <PageHeader onSettingsButtonClick={() => setSettingsDialogOpen(true)} />

      {/* Settings dialog */}

      <SettingsDialog
        settings={settings}
        onChange={onSettingsChange}
        onClose={onSettingsDialogClose}
        open={settingsDialogOpened}
      />

      {/* End Settings dialog */}

      <Container
        maxWidth="md"
        sx={{ mb: 5, mt: 5 }}
      >
        {/* Notes Section */}

        <section
          style={{
            paddingTop: (notes.notes.length > 0 ? '60px' : '0'),
          }}
        >
          <NotesSection notes={notes.notes} />
        </section>

        {/* Notes End Section */}

        {/* Maintenance Cards Area */}

        <section
          style={{
            marginBottom: '20px',
            paddingTop: '30px',
          }}
        >
          <Typography variant="h2">Upcoming Maintenance</Typography>

          {store.maintenanceStore.loadingMaintenances ? (
            <CircularProgress />
          ) : (
            <MaintenanceWindowsList
              maintenanceWindows={store.maintenanceStore.upcomingMaintenances}
              timeZone={settings.timeZone}
            />
          )}
        </section>

        {/* End Maintenance Cards Area */}

        {/* Availability Cards Area */}

        <section
          style={{
            display: 'flex',
            flexFlow: 'column',
          }}
        >
          <Typography variant="h2">Availability</Typography>

          {monitorList.length === 0 ? (
            <CircularProgress />
          ) : (
            <MonitorList monitors={monitorList} />
          )}
        </section>

        {/* End Availability Cards Area */}

        <Typography
          sx={{
            mt: 3,
            fontSize: '14px',
          }}
        >
          Last updated at:
          {' '}

          {updatedAt.toLocaleString('en-US', { timeZone: settings.timeZone })}
        </Typography>
      </Container>
    </>
  );
});

export default HomePage;
