import { getTimeZones } from '@vvo/tzdb';
import { flow, types } from 'mobx-state-tree';

import MaintenanceModel from './models/MaintenanceModel';
import { getUpcomingMaintenance, getMaintenanceDetails } from '../api/dynatrace';

const availableTimeZone = getTimeZones({ includeUtc: true });

// TODO move deserialization functions elsewhere + add UT
const dayMap = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

/**
 * @param {string} dayOfWeek 
 * @param {string} startTime 
 * @param {number} durationMinutes 
 * @returns {Array<Date>}
 */
function getNextMaintenanceWeeklyRecurence(dayOfWeek, startTime, durationMinutes) {
  const currentDateTime = new Date();
  const startsAt = new Date(
    currentDateTime.setDate(
      currentDateTime.getDate() + ((7 - currentDateTime.getDay() + dayMap[dayOfWeek]) % 7 || 7),
    ),
  );
  const [startHour, startMinutes] = startTime.split(':');
  startsAt.setUTCHours(startHour);
  startsAt.setUTCMinutes(startMinutes);
  startsAt.setUTCSeconds(0);

  const endsAt = new Date(startsAt.getTime() + durationMinutes * 60000);

  return [startsAt, endsAt];
}

/**
 * 
 * @param {string} startTime 
 * @param {number} durationMinutes 
 * @returns {Array<Date>}
 */
function getNextMaintenanceDailyRecurence(startTime, durationMinutes) {
  const startsAt = new Date();
  const [startHour, startMinutes] = startTime.split(':');
  startsAt.setUTCHours(startHour);
  startsAt.setUTCMinutes(startMinutes);
  startsAt.setUTCSeconds(0);
  const endsAt = new Date(startsAt.getTime() + durationMinutes * 60000);

  if (endsAt.getTime() < new Date().getTime()) {
    // Maintenance is finished today
    const nextDay = startsAt.getDate() + 1;
    startsAt.setDate(nextDay);
    endsAt.setDate(nextDay);
  }

  return [startsAt, endsAt];
}

function deserializeMainteanceDetails(maintenanceDetails) {
  const isPublic = Boolean(maintenanceDetails.description && maintenanceDetails.description.startsWith('[Status Page]'));
  let { description } = maintenanceDetails;
  if (isPublic) {
    description = maintenanceDetails.description.replace('[Status Page]', '').trimStart();
  }

  const { zoneId } = maintenanceDetails.schedule;

  // Compute the offset based on schedule.zoneId property so we build the Date object with the correct timezone

  const timezone = availableTimeZone.find((tz) => tz.group.includes(zoneId) || tz.abbreviation === zoneId);
  let offset = '+0000';
  if (timezone) { // zoneId is using IANA Time Zone Database format, we were able to match it
    const absOffset = (timezone.currentTimeOffsetInMinutes < 0
      ? (-timezone.currentTimeOffsetInMinutes)
      : timezone.currentTimeOffsetInMinutes);
    const hourOffset = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 2,
    }).format(Math.floor(absOffset / 60));
    const minuteOffset = new Intl.NumberFormat('en-US', {
      minimumIntegerDigits: 2,
    }).format(absOffset % 60);

    const sign = timezone.currentTimeOffsetInMinutes > 0 ? '+' : '-';
    offset = `${sign}${hourOffset}${minuteOffset}`;
  } else if (zoneId !== 'UTC') {
    // zoneId is UTC offset : UTC+XX:XX
    offset = zoneId.replace('UTC', '').replace(':', '');
  }
  // maintenanceDetails.schedule.start/end are using 'yyyy-MM-dd HH:mm' format,
  // we can append the computed offset
  const scheduleStart = new Date(`${maintenanceDetails.schedule.start}${offset}`);
  const scheduleEnd = new Date(`${maintenanceDetails.schedule.end}${offset}`);

  const props = {
    id: maintenanceDetails.id,
    name: maintenanceDetails.name,
    description,
    scheduleRecurenceType: maintenanceDetails.schedule.recurrenceType,
    type: maintenanceDetails.type,
    enabled: maintenanceDetails.enabled,
    isPublic,
    scheduleStart,
    scheduleEnd,
  };
  if (maintenanceDetails.schedule.recurrenceType === 'ONCE') {
    props.nextWindowStartsAt = props.scheduleStart;
    props.nextWindowEndsAt = props.scheduleEnd;
  } else if (maintenanceDetails.schedule.recurrence) {
    switch (maintenanceDetails.schedule.recurrenceType) {
      case 'WEEKLY':
        [props.nextWindowStartsAt, props.nextWindowEndsAt] = getNextMaintenanceWeeklyRecurence(
          maintenanceDetails.schedule.recurrence.dayOfWeek,
          maintenanceDetails.schedule.recurrence.startTime,
          maintenanceDetails.schedule.recurrence.durationMinutes,
        );
        break;
      case 'DAILY':
        [props.nextWindowStartsAt, props.nextWindowEndsAt] = getNextMaintenanceDailyRecurence(
          maintenanceDetails.schedule.recurrence.startTime,
          maintenanceDetails.schedule.recurrence.durationMinutes,
        );
        break;
      default:
        console.warn(`Unsupported recurrenceType ${maintenanceDetails.schedule.recurrenceType}`);
        break;
    }
  }

  return MaintenanceModel.create(props);
}

const MaintenanceStore = types.model('MaintenanceStore', {
  maintenances: types.map(MaintenanceModel),
  loadingMaintenances: types.boolean,
})
  .views((self) => ({
  // TODO maybe we should filter according to a time a bit in the past (instead of current time) so customer can see maintenance that just finished 
    get upcomingMaintenances() {
      const currentTime = new Date().getTime();
      return Array.from(self.maintenances.values())
        .filter(
          (maintenance) => maintenance.isPublic && maintenance.enabled && maintenance.scheduleEnd.getTime() > currentTime,
        )
        .sort(
          (maintenanceA, maintenanceB) => maintenanceA.nextWindowEndsAt.getTime() - maintenanceB.nextWindowEndsAt.getTime(),
        );
    },
  }))
  .actions((self) => ({
    addMaintenance(maintenance) {
      self.maintenances.set(maintenance.id, maintenance);
    },
    fetchMaintenances: flow(function* genFetchMaintenance() {
      self.loadingMaintenances = true;

      yield getUpcomingMaintenance().then((response) => Promise.all(
        response.data.values.map((maintenance) => getMaintenanceDetails(maintenance.id)),
      )).then((maintenanceDetailsResponses) => {
        maintenanceDetailsResponses.map((response) => response.data).forEach((maintenance) => {
          self.addMaintenance(deserializeMainteanceDetails(maintenance));
        });
      });
      self.loadingMaintenances = false;
    }),
  }));

export default MaintenanceStore;
