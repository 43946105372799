/* eslint-disable max-len */
import SettingsIcon from '@mui/icons-material/Settings';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import PropTypes from 'prop-types';

const propTypes = {
  onSettingsButtonClick: PropTypes.func,
};

const PageHeader = ({ onSettingsButtonClick }) => (
  <Box sx={{ flexGrow: 1 }}>
    <AppBar elevation={1} style={{ backgroundColor: 'white' }}>
      <Container maxWidth="lg">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }}>
            <img alt="" src="/images/wiley.jpg" height="40" />
          </Box>

          <IconButton
            size="large"
            aria-label="configuration"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => onSettingsButtonClick()}
          >
            <SettingsIcon fontSize="inherit" />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  </Box>
);

PageHeader.propTypes = propTypes;

export default PageHeader;
