/* eslint-disable no-process-env */
export const DYNATRACE_API_TOKEN = 'DYNATRACE_API_TOKEN';
export const DYNATRACE_API_BASE_URL = 'DYNATRACE_BASE_URL';
export const DYNATRACE_DASHBOARD_ID = 'DYNATRACE_DASHBOARD_ID';
export const APP_LOGO = 'APP_LOGO';
export const APP_TITLE = 'APP_TITLE';
export const NODE_ENV = 'NODE_ENV';

export class ConfigClient {
  static config = {};

  /**
   * Initiate the configuration
   */
  static init() {
    ConfigClient.config[NODE_ENV] = window.env.NODE_ENV;
    ConfigClient.config[DYNATRACE_API_TOKEN] = window.env.PUBLIC_DYNATRACE_API_TOKEN;
    ConfigClient.config[DYNATRACE_API_BASE_URL] = window.env.PUBLIC_DYNATRACE_API_BASE_URL;
    ConfigClient.config[DYNATRACE_DASHBOARD_ID] = window.env.PUBLIC_DYNATRACE_DASHBOARD_ID;
  }

  static get(configName) {
    return ConfigClient.config[configName];
  }
}
