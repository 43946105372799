import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import './MonitorList.scss';
import PropTypes from 'prop-types';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const propTypes = {
  monitors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      metrics: PropTypes.shape({
        avgValue: PropTypes.number,
      }),
    }),
  ),
};
function MonitorList({ monitors }) {
  const renderStatus = (upStatus) => {
    if (upStatus) return <span className="excelentStatus">Up</span>;
    if (!upStatus) return <span className="poorStatus">Down</span>;
  };

  const renderPercentage = (avgValue) => {
    if (!avgValue) return <CircularProgress size="14px" sx={{ mr: 1.5 }} />;
    if (avgValue > 99.5) return <span className="excelentStatus">{`${avgValue.toFixed(2)}%`}</span>;
    if (avgValue >= 95) return <span className="fairStatus">{`${avgValue.toFixed(2)}%`}</span>;
    if (avgValue < 95) return <span className="poorStatus">{`${avgValue.toFixed(2)}%`}</span>;
  };

  return (
    <TableContainer component={Paper}>
      <Table
        aria-label="simple table"
      >
        <TableHead>
          <TableRow sx={{ fontWeight: 'bold' }}>
            <StyledTableCell sx={{ color: '#494D4B' }}>Name</StyledTableCell>

            <StyledTableCell sx={{ color: '#494D4B' }} align="right">Status</StyledTableCell>

            <StyledTableCell sx={{ color: '#494D4B' }} align="right">Uptime</StyledTableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {monitors.map((monitor, monitorIndex) => (
            <StyledTableRow
              key={monitor.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              id={`panel${monitorIndex}-header`}
            >
              <StyledTableCell sx={{ color: '#494D4B' }}>
                {monitor.name}
              </StyledTableCell>

              <StyledTableCell
                sx={{ fontWeight: 'bold' }}
                className="monitorStatus"
                align="right"
              >
                {renderStatus(monitor.up)}
              </StyledTableCell>

              <StyledTableCell
                sx={{ fontWeight: 'normal' }}
                className="monitorStatus"
                align="right"
              >
                {renderPercentage(monitor.metrics?.avgValue)}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

MonitorList.propTypes = propTypes;

export default MonitorList;
