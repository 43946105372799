import { useEffect, useState } from 'react';

import { AutorenewOutlined, PendingActionsOutlined } from '@mui/icons-material';
import {
  Avatar, Box, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Stack, Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import CardMaintenance from '../CardMaintenance/CardMaintenance';

const relativeTimeFormatter = new Intl.RelativeTimeFormat('en-US');

function getRelativeStartTime(maintenanceWindow, currentTime) {
  const timeGap = maintenanceWindow.nextWindowStartsAt.getTime() - currentTime;

  const absoluteTimeGap = timeGap < 0 ? -timeGap : timeGap;

  let relativeTimeUnit; let
    relativeTimeValue;
  if (absoluteTimeGap > 86_400_000) {
    relativeTimeUnit = 'day';
    relativeTimeValue = Math.floor(timeGap / 86_400_000);
  } else if (absoluteTimeGap > 3_600_000) {
    relativeTimeUnit = 'hour';
    relativeTimeValue = Math.floor(timeGap / 3_600_000);
  } else {
    relativeTimeUnit = 'minute';
    relativeTimeValue = Math.floor(timeGap / 60_000);
  }

  return (timeGap > 0
    ? `Starts ${relativeTimeFormatter.format(relativeTimeValue, relativeTimeUnit)}`
    : `Started ${relativeTimeFormatter.format(relativeTimeValue, relativeTimeUnit)}`
  );
}

const propTypes = {
  maintenanceWindows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
      nextWindowEndsAt: PropTypes.instanceOf(Date),
      nextWindowStartsAt: PropTypes.instanceOf(Date),
    }),
  ),
  timeZone: PropTypes.string,
};

function MaintenanceWindowsList({ maintenanceWindows, timeZone }) {
  const timeFormatter = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'short',
    timeStyle: 'short',
    timeZone,
  });

  // Fix locale to en-US since all text is displayed in this locale anyway
  const minutesFormatter = new Intl.NumberFormat('en-US', {
    style: 'unit',
    unit: 'minute',
    unitDisplay: 'short',
  });

  const [selectedMaintenance, setSelectedMaintenance] = useState(null);
  const [currentTime, setCurrentTime] = useState(Date.now());

  useEffect(() => {
    // Update current time relatives time can update
    const interval = setInterval(() => setCurrentTime(Date.now()), 30_000);
    return function cleanUpInterval() {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (maintenanceWindows[0]) {
      setSelectedMaintenance(maintenanceWindows[0]);
    }
  }, [maintenanceWindows]);

  const handleListItemClick = (event, maintenance) => {
    setSelectedMaintenance(maintenance);
  };

  if (maintenanceWindows.length === 0) {
    return (
      <Typography variant="body1">
        No upcoming maintenance
      </Typography>
    );
  }

  return (
    <Stack>
      <Typography
        variant="body2"
        sx={{
          mb: 1,
        }}
      >
        Select a maintenance window to see the details
      </Typography>

      <Stack
        sx={{
          display: 'flex',
          flexDirection: {
            xs: 'column',
            md: 'row',
          },
        }}
      >
        <style>
          {`
              @keyframes spin {
                0% { transform: rotate(360deg); }
                100% { transform: rotate(0deg); }
              }
              `}
        </style>

        <Paper variant="outlined">
          <List
            dense
            sx={{
              width: '100%',
              padding: 0,
            }}
          >
            {maintenanceWindows.map((maintenanceWindow) => {
              const isRunning = currentTime >= maintenanceWindow.nextWindowStartsAt.getTime();
              let icon = <PendingActionsOutlined />;

              if (isRunning) {
                icon = <AutorenewOutlined sx={{ animation: 'spin 4s linear infinite' }} />;
              }

              const formattedRelativeStartTime = getRelativeStartTime(maintenanceWindow, currentTime);
              const formattedNextWindowStartDatetime = timeFormatter.format(maintenanceWindow.nextWindowStartsAt);
              const formattedDuration = minutesFormatter.format(maintenanceWindow.durationMinutes);

              return (
                <ListItemButton
                  key={maintenanceWindow.id}
                  onClick={(event) => handleListItemClick(event, maintenanceWindow)}
                  selected={selectedMaintenance && selectedMaintenance.id === maintenanceWindow.id}
                  divider
                >
                  <ListItemAvatar>
                    <Avatar>
                      {icon}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={maintenanceWindow.name}
                    secondary={`${formattedRelativeStartTime} - ${formattedNextWindowStartDatetime} - ${formattedDuration}`}
                  />
                </ListItemButton>
              );
            })}
          </List>
        </Paper>

        {selectedMaintenance && (
          <Box sx={{ flex: 1 }}>
            <Box style={{ position: 'sticky', top: '120px' }}>
              <CardMaintenance item={selectedMaintenance} timeZone={timeZone} />
            </Box>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}
MaintenanceWindowsList.propTypes = propTypes;
export default MaintenanceWindowsList;
