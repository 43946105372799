import { types } from 'mobx-state-tree';

import MaintenanceStore from './maintenanceStore';
import MetricStore from './metricStore';

const RootStore = types.model('RootStore', {
  metricStore: MetricStore,
  maintenanceStore: MaintenanceStore,
});

export function createStore() {
  const metricStore = MetricStore.create({
    monitors: {},
    loadingMonitors: false,
  });
  const maintenanceStore = MaintenanceStore.create({
    maintenances: {},
    loadingMaintenances: false,
  });

  const store = RootStore.create({
    metricStore,
    maintenanceStore,
  });

  return store;
}
